// Here you can add other styles

.cardStyle {
  //max-height: 230px;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #d8dbe0;
  background: #ffffff;
}

#styleButtonBack{
  background-color: #2e8ae6;
  color:#ffff;
  border: none;
  margin-bottom: 6px;

}

.divContenedorTitulo{
  text-align: center;
  margin:10px;


}
#colPadding {
  padding: 3px !important;
}

.tituloInput {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;

}

#contenedorBtn {
  padding: 15px;
  text-align: center;
}

#pagin{
  margin-top: 10px
}

#pagin{
  float: right;
  margin-left: 15px;
}
#pagin > .pagination > li > a{
  color:#fff;
  padding: 8px;
  font-weight: 400;
  border: 1px solid #575757;
  border-radius: 5px;
  background-color: #3e4449;
}
#pagin > .pagination>.active>a{
  z-index: 3;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  background-color: #ef3937;
  border-color: #ef3937;
  cursor: default;
}


#botonStyle {
  background-color: #7888b3;
  font-size: 13px;
  color: #ffffff;
  min-width: 87px;
}

//LOGIN
@media (min-width: 1200px) {
  //.container-xl, .container-lg, .container-md, .container-sm, .container {
  //  max-width: 790px;
  //}
}

@media (min-width: 992px) {
  //.container, .container-lg, .container-md, .container-sm {
  //  width: 100%;
  //}
}

//MODAL
.modal-dialog {
  max-width: 829px;
  margin: 1.75rem auto;
}

//TAB

.tab-content {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: -1px;
  border: none;
  color: #000000;
  font-weight: 500;
  height: auto;
  padding-bottom: 10px;
}

.nav-tabs {
  font-size: 12px;
  font-weight: 500;
}

.nav-tabs .nav-link.active {
  color: #7888b3;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
}
